@font-face {
  font-family: 'Noto Color Emoji';
  src:
    url('../public/fonts/NotoColorEmoji-flagsonly.woff2') format('woff2'),
    url('../public/fonts/NotoColorEmoji-flagsonly.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url('../public/fonts/Montserrat-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url('../public/fonts/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url('../public/fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url('../public/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url('../public/fonts/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url('../public/fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
